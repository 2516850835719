import React from 'react'
import "./footer.css"
import logo from "../../assets/l4i-logo.svg"

const wpNum = process.env.REACT_APP_WP_NUM

const Footer = () => {
  return (
    <div className="gpt3__footer section__padding">
      <div className="gpt3__footer-heading">
        <h1 className="gradient__text">
        Quer estar na vanguarda do futuro antes dos outros?
        </h1>
      </div>
      <div className="gpt3__footer-button">
        <p><a href={`https://wa.me/${wpNum}?text=Quero%20fazer%20parte%20do%20futuro%20com%20a%20L4I`} target="_blank" rel="noreferrer">Whatsapp</a></p>
      </div>
      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
            <img src={logo} alt="L4I Logo"/>
          <p>Lajeado, Rio Grande do Sul, Brasil. Todos os direitos reservados</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Links</h4>
          <p>Blog</p>
          <p>Cases de sucesso</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Empresa</h4>
          <p>Termos e Condições</p>
          <p>Política de Privacidade</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Entrar em contato</h4>
          <p>Lajeado, Rio Grande do Sul, Brasil</p>
          <p><a href={`https://wa.me/${wpNum}?text=Quero%20fazer%20parte%20do%20futuro%20com%20a%20L4I`} target="_blank" rel="noreferrer">+{wpNum}</a></p>
        </div>
      </div>
      <div className="gpt3__footer-copyrights">
        ©2024 {'</>'} L4I. Todos os direitos reservados
      </div>
    </div>
  )
}

export default Footer