import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="l4i">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="</> L4I?" text="Somos profissionais de mercado! Tecnologia, estratégia, gestão e processos estão no DNA da nossa equipe. Nossa missão é redefinir as fronteiras do possível, impulsionando sua empresa rumo a uma excelência inigualável. Com simplificação e eficácia, promovemos uma transformação que ultrapassa os limites convencionais. Buscamos conectar pessoas, ideias e tecnologia para criar soluções personalizadas e únicas."/>
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">O potencial vai além da nossa imaginação</h1>
      <p>Explore o nosso portfólio de serviços</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="E&P" text="Desbloqueie o máximo potencial do seu negócio. Garanta o alinhamento entre a sua estratégia e seus processos. Nosso foco na simplicidade garante resultados eficazes e duradouros." />
      <Feature title="Treinamentos IA" text="Mãos na massa! Facilitamos para a sua equipe o conhecimento prático nas ferramentas de inteligência artificial mais usadas do mercado." />
      <Feature title="Liderança Exponencial" text="Preparamos líderes para enfrentar ambientes mais frágeis, ansiosos, não-lineares e incompreensíveis. Os 10 Cs do líder exponencial sempre estarão presentes." />
      <Feature title="LEGO" text="Gamification. Fazemos do jogo a nossa estratégia de ensino e mudanças. Aplicamos diferentes técnicas de ludificação para melhorar o trabalho das equipes." />
      <Feature title="Agilidade" text="A sua equipe pode fazer mais e melhor. Direcionamos marcos de trabalho ágeis adaptados à realidade do seu setor e negócio." />
      <Feature title="Chatbots humanizados" text="Facilitamos o trabalho comercial, de atendimento e suporte. Permitimos conversas mais naturais, mais humanas e mais individualizadas para o seu cliente." />
    </div>
  </div>
);

export default WhatGPT3;