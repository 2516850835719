import React from 'react'
import "./possibility.css"

import  possibilityImage  from '../../assets/possibility.png'

const Possibility = () => {
  return (
    <div className="gpt3__possibility section__padding" id='futuro'>
        <div className="gpt3__possibility-image">
          <img src={possibilityImage} alt="possibility" />
        </div>
        <div className="gpt3__possibility-container">
          <p><a href="/">Peça uma demo e faça parte do Futuro</a></p>
          <h1>O potencial vai além da nossa imaginação</h1>
          <p>Descubra como desbloquear o potencial ilimitado do seu negócio conosco. Na nossa jornada de colaboração, vamos além do convencional, explorando ideias e estratégias que transcendem os limites da imaginação. Estamos aqui para guiar você rumo ao sucesso, aproveitando todo o potencial que o futuro tem a oferecer. Agende agora uma conversa conosco e juntos vamos criar um caminho personalizado para alcançar seus objetivos empresariais.</p>
        </div>
    </div>
  )
}

export default Possibility