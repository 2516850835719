import React from 'react'
import { Feature } from '../../components'
import "./features.css"

const wpNum = process.env.REACT_APP_WP_NUM

const featuresData = [
  {
    title: "Organize sua empresa",
    text: "Avançar para o futuro requer um presente sólido, organizado e estável. Sabemos como apoiar."
  },
  {
    title: "Criatividade e Inovação",
    text: "Nossos serviços se caracterizam por pensar em quem irá usá-los, jamais em quem os desenvolveu."
  },
  {
    title: "TD e IA",
    text: "Orientamos sobre projetos e ferramentas que facilitem a Transformação Digital e o uso da Inteligência Artificial."
  }
]

const Features = () => {
  return (
    <div className="gpt3__features section__padding" id="casos">
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">O futuro é agora e está ao seu alcance. Venha fazer parte dele e torne-o realidade</h1>
        <p><a href={`https://wa.me/${wpNum}?text=Quero%20fazer%20parte%20do%20futuro%20com%20a%20L4I`} target="_blank" rel="noreferrer">Converse conosco sobre o futuro</a></p>
      </div>
      <div className="gpt3__features-container">
        <div>
        {featuresData.map((item, index) =>
          <Feature title={item.title} text={item.text} key={item.title + index}/>
        )}
        </div>
      </div>
    </div>
  )
}

export default Features