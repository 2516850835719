import React from 'react'
import "./cta.css"

const wpNum = process.env.REACT_APP_WP_NUM

const CTA = () => {
  return (
    <div className="gpt3__cta section__margin">
      <div className="gpt3__cta-content">
        <p>Solicite detalhes sobre os nossos serviços</p>
        <h1>Escreva agora e construa o futuro que sempre sonhou.</h1>
      </div>
      <button><a href={`https://wa.me/${wpNum}?text=Quero%20fazer%20parte%20do%20futuro%20com%20a%20L4I`} target="_blank" rel="noreferrer">Escrever</a></button>
    </div>
  )
}

export default CTA