import React from 'react'
import "./header.css"
import ai from "../../assets/ai.png"

const Header = () => {
  return (
    <div className="gpt3__header section_padding" id='home'>
      <div className="gpt3__header-content">
        <h1 className="gradient__text">Elevamos o nível da gestão e transformação das empresas</h1>
        <p>Desperte a transformação digital em seu negócio com nossa expertise em estratégia, gestão e processos. Nossa abordagem ágil e inovadora impulsiona a eficiência do seu negócio, enquanto nossos treinamentos em inteligência artificial capacitam sua equipe para o futuro. Juntos, ultrapassamos os limites, reinventando o sucesso empresarial.</p>

      </div>
      <div className="gpt3__header-image">
        <img src={ai} alt="AI" />
      </div>
    </div>
  )
}

export default Header